<template>
  <Loading v-if="isLoading" />
  <div v-else class="users-list">
    <b-card>
      <div class="p-2 d-flex align-items-center justify-content-between">
        <h1 class="m-0 page-title">{{ $t("g.users/page_title") }}</h1>
        <div
          v-if="permission && permission.vendor.createSubVendor"
          class="d-flex align-items-center"
        >
          <b-link
            :to="{ name: 'addUser' }"
            role="button"
            class="btn btn-primary"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("g.users/user_list/add_user") }}
          </b-link>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span v-else-if="props.column.label === 'email'" class="text-nowrap">
            {{ $t("g.email") }}
          </span>
          <span
            v-else-if="props.column.label === 'permissions'"
            class="text-nowrap"
          >
            {{ $t("users.role") }}
          </span>
          <span v-else-if="props.column.label === 'phone'" class="text-nowrap">
            {{ $t("users.phone") }}
          </span>
          <span
            v-else-if="props.column.label === 'is_blocked'"
            class="text-nowrap"
          >
            {{ $t("users.blocked") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap d-flex align-items-center"
          >
            <b-avatar
              class="align-self-start mr-1"
              variant="info"
              :src="getAvatarImage(props.row.media, props.row.first_name)"
            />
            {{ props.row.first_name }}
            {{ props.row.last_name }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span v-else-if="props.column.field === 'phone'" class="text-nowrap">
            {{
              props.row.phone.length ? props.row.phone[0].phone : $t("noData")
            }}
          </span>
          <span
            v-else-if="props.column.field === 'permissions'"
            class="text-nowrap d-flex align-items-start"
          >
            {{ props.row.role }}
          </span>
          <span
            v-else-if="props.column.field === 'is_blocked'"
            class="text-nowrap custom-toggle d-flex align-items-start"
          >
            <b-form-checkbox
              :disabled="permission && !permission.vendor.updateSubVendor"
              v-model="props.row.is_blocked"
              switch
              @change="(e) => handleBlockUser(e, props.row.id)"
            />
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex align-items-start"
          >
            <span>
              <b-dropdown
                v-if="
                  permission &&
                  (permission.vendor.deleteSubVendor ||
                    permission.vendor.updateSubVendor)
                "
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="permission && permission.vendor.updateSubVendor"
                  :to="{ name: 'editUser', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="permission && permission.vendor.deleteSubVendor"
                  @click="deleteUser(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span class="d-flex align-items-start" v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="emptystate">{{ $t("g.no_data") }}</div>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date.js";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import helper from "@/composables/helper";

export default {
  name: "UsersList",
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    BLink,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      tableBody: [],
      userId: null,
      formateDate: dateTimeInFormat,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "name",
          field: "name",
        },
        {
          label: "email",
          field: "email",
        },
        {
          label: "phone",
          field: "phone",
        },
        {
          label: "permissions",
          field: "permissions",
        },
        {
          label: "is_blocked",
          field: "is_blocked",
          sortable: false,
        },
        {
          label: "action",
          field: "action",
        },
      ],
    };
  },
  computed: {
    currentUserId() {
      return this.$store?.getters?.getUserData;
    },
    permission() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  beforeMount() {
    this.getUsersList();
  },
  methods: {
    async getUsersList() {
      try {
        const response = (await this.$http.get("vendor/my-vendors")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
          el.phone_number = el.phone[0]?.phone;
        });
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    handleDeleteUser(userId) {
      this.$http
        .delete(`vendor/delete/${userId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getUsersList();
          }
        })
        .catch((err) => {});
    },
    async deleteUser(userId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeleteUser(userId)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    async handleBlockUser(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_blocked: checked,
        };
        const res = await this.$http.post(
          `vendor/update/${id}?_method=put`,
          data
        );
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },

    getAvatarImage(images, name) {
      let mainImage;
      if (images?.length > 0) {
        mainImage = images.filter((image) => image.name === "avatar");
      }
      if (mainImage?.length > 0) {
        return mainImage[0].path;
      } else {
        return helper.uiAvatar(name);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "./_index";
</style>
